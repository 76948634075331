const STD_MDR = '3.9';
const MDR_DESCRIPTIONS = {
    '3.9': '(0% for 3mo and all other terms)'
    , '4.9': '(0% for 6mo)'
    , '6.9': '(0% for 12mo)'
    , '9.9': '(0% for 24mo)'
}

export const getOfferInfo = (offers, defaultMdrFallback) => {
    if (offers == null) return
    let info = {}
    if (offers.selectedMdr) {
        info.mdrs = offers.selectedMdr + '%'
        return info
    } else {
        if (!offers.mdrs || offers.mdrs.length < 1) {
            if (defaultMdrFallback) {
                offers.mdrs = [STD_MDR]
            } else return
        }

        if (offers.minMaxApr) {
            info.terms = {
                apr: offers.minMaxApr + '% APR,',
                months: offers.minMaxMonths + ' months'
            }
        }

        if (offers.mdrs.length === 1) {
            info.mdrs = offers.mdrs[0] + '%'
        } else {
            let mdrs = []
            offers.mdrs.sort((a, b) => parseFloat(a) - parseFloat(b))
            offers.mdrs.forEach(mdr => mdrs.push(composeMdr(mdr)))
            info.mdrs = mdrs.join('\n');
        }
        return info
    }
}


const composeMdr = (mdr) => {
    if (!MDR_DESCRIPTIONS[mdr]) return mdr + '%'
    return mdr + '% ' + MDR_DESCRIPTIONS[mdr]
}