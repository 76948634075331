export const API_ERROR_RESPONSE = "API_ERROR_RESPONSE";
export const CLEAR_API_ERROR = "CLEAR_API_ERROR";
export const MERCHANT_SUBMIT_USERNAME = "MERCHANT_SUBMIT_USERNAME";
export const MERCHANT_SUBMIT_EMAIL = "MERCHANT_SUBMIT_EMAIL";
export const CONSUMER_GET_VERTICAL_MESSAGES = "CONSUMER_GET_VERTICAL_MESSAGES";
export const MERCHANT_SUBMIT_PIN = "MERCHANT_SUBMIT_PIN";
export const MERCHANT_SUBMIT_EMAIL_PIN = "MERCHANT_SUBMIT_EMAIL_PIN";
export const MERCHANT_GET_TRANSACTIONS = "MERCHANT_GET_TRANSACTIONS";
export const MERCHANT_EXPORT_TRANSACTIONS = "MERCHANT_EXPORT_TRANSACTIONS";
export const MERCHANT_GET_ME = "MERCHANT_GET_ME";
export const SETTINGS_UPDATE_USER = "settings/updateUser";
export const MERCHANT_UPDATE_USER_BY_ADMIN = "MERCHANT_UPDATE_USER_BY_ADMIN";
export const MERCHANT_ADD_USER = "MERCHANT_ADD_USER";
export const MERCHANT_DELETE_USER = "MERCHANT_DELETE_USER";
export const MERCHANT_GET_USERS = "MERCHANT_GET_USERS";
export const MERCHANT_SET_TRANSACTION_DATA = "MERCHANT_SET_TRANSACTION_DATA";
export const MERCHANT_GET_TRANSACTION = "MERCHANT_GET_TRANSACTION";
export const MERCHANT_LOG_OUT = "MERCHANT_LOG_OUT";
export const MERCHANT_SET_USER = "MERCHANT_SET_USER";
export const MERCHANT_SET_USER_IN_EDIT = "MERCHANT_SET_USER_IN_EDIT";
export const MERCHANT_CREATE_TRANSACTION = "MERCHANT_CREATE_TRANSACTION";
export const MERCHANT_REMINDER = "MERCHANT_REMINDER"
export const SETTINGS_UPDATE_BUSINESS = "settings/updateBusiness";
export const APPLY_FILTER = "APPLY_FILTER";
export const APPLY_PREQUAL_FILTER = "APPLY_PREQUAL_FILTER";
export const SIGNUP_ZIP = "SIGNUP_ZIP";
export const MERCHANT_MANUAL_ACCOUNT_DATA = "MERCHANT_MANUAL_ACCOUNT_DATA";
export const UPDATE_MERCHANT_BANK_INFO = "UPDATE_MERCHANT_BANK_INFO";
export const CREATE_LINK_TOKEN = "CREATE_LINK_TOKEN";
export const ACCEPT_PLAID_TOKEN = "ACCEPT_PLAID_TOKEN";
export const MERCHANT_API_ERROR = "MERCHANT_API_ERROR";
export const MERCHANT_NEW_PREQUAL = "MERCHANT_NEW_PREQUAL";
export const MERCHANT_GET_PREQUALS = "MERCHANT_GET_PREQUALS";
export const MERCHANT_EXPORT_PREQUALS = "MERCHANT_EXPORT_PREQUALS";
export const MERCHANT_GET_PREQUAL = "MERCHANT_GET_PREQUAL";
export const MERCHANT_SET_PREQUAL_DATA = "MERCHANT_SET_PREQUAL_DATA";
export const DYNAMIC_USER_FILTER = "DYNAMIC_USER_FILTER";
export const DO_NOTHING = "DO_NOTHING";

