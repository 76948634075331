import {
    ACCEPT_PLAID_TOKEN,
    API_ERROR_RESPONSE,
    APPLY_FILTER,
    APPLY_PREQUAL_FILTER,
    CONSUMER_GET_VERTICAL_MESSAGES,
    CREATE_LINK_TOKEN,
    MERCHANT_ADD_USER,
    MERCHANT_API_ERROR,
    MERCHANT_CREATE_TRANSACTION,
    MERCHANT_DELETE_USER,
    MERCHANT_EXPORT_PREQUALS,
    MERCHANT_EXPORT_TRANSACTIONS,
    MERCHANT_GET_ME,
    MERCHANT_GET_PREQUAL,
    MERCHANT_GET_PREQUALS,
    DYNAMIC_USER_FILTER,
    MERCHANT_GET_TRANSACTION,
    MERCHANT_GET_TRANSACTIONS,
    MERCHANT_GET_USERS,
    MERCHANT_LOG_OUT,
    MERCHANT_MANUAL_ACCOUNT_DATA,
    MERCHANT_NEW_PREQUAL,
    MERCHANT_REMINDER,
    MERCHANT_SET_PREQUAL_DATA,
    MERCHANT_SET_TRANSACTION_DATA,
    MERCHANT_SET_USER,
    MERCHANT_SET_USER_IN_EDIT,
    MERCHANT_SUBMIT_EMAIL,
    MERCHANT_SUBMIT_EMAIL_PIN,
    MERCHANT_SUBMIT_PIN,
    MERCHANT_SUBMIT_USERNAME,
    MERCHANT_UPDATE_USER_BY_ADMIN,
    SETTINGS_UPDATE_BUSINESS,
    SETTINGS_UPDATE_USER,
    SIGNUP_ZIP,
    UPDATE_MERCHANT_BANK_INFO
} from '../actions/types'

import {decodeToken} from "@wisetack/shared-ui/utils/format";

import {API_REQUEST_ENDED, API_REQUEST_STARTED} from "@wisetack/shared-ui/utils/Api";
import {initMonths, initPrequalStatuses, initStatuses, initYears} from "../../utils/init";
import moment from "moment";
import {setAmplitudeUserId} from "@wisetack/shared-ui/src/components/Amplitude";
import { datadogRum } from '@datadog/browser-rum'

const initState = {
    isLoading: false,
    errorMessage: "",
    fieldsError: {},
    filter: {
        statuses: initStatuses(),
        years: initYears(),
        months: initMonths(),
    },
    manualAccountData: {},
    linkTokens: {},
    merchantUpdate: {},
    merchantUsers: {},
    prequalFilter: {
        statuses: initPrequalStatuses(),
        years: initYears(),
        months: initMonths(),
        users: {}
    },
    apiRequests: {},
    apiError: {}
}

const updateBusinessData = (payload, state) => {

    const merchantId = payload.id;

    if (!merchantId) {
        return
    }

    const oldMerchants = state.merchants

    if (oldMerchants) {

        const oldMerchant = state.merchants[merchantId]

        if (oldMerchant) {
            const merchant = {
                ...oldMerchant
            }

            for (const [name, value] of Object.entries(payload)) {
                merchant[name] = value
            }

            const merchants = {
                ...oldMerchants
            }

            merchants[merchantId] = merchant
            return { merchant, merchants }
        }
    }
}

const mergeMerchantUser = (payload, state) => {

    const merchantId = payload.merchantId

    if (!merchantId) return

    const oldMerchantUsers = state.merchantUsers

    const users = Object.assign({}, oldMerchantUsers[merchantId], { [payload.userId]: payload })

    const merchantUsers = Object.assign(oldMerchantUsers, { [merchantId]: users })

    return { merchantUsers }
}

const mergeMerchantUsers = (payload, state) => {

    const payloadUsers = payload.users;
    if (!payloadUsers || payloadUsers.length === 0) {
        return
    }

    const merchantId = payloadUsers[0].merchantId;

    let existingMerchantUsers = state.merchantUsers;

    let users = {};
    payloadUsers.forEach(payloadUser => {
        users[payloadUser.userId] = payloadUser
    })

    let merchantUsers = Object.assign({}, existingMerchantUsers, { [merchantId]: users })

    return { merchantUsers }
}

const extractUserFilter = (payload, state) =>{

    let users = payload.users;
    let userFilter = {};
    users.forEach( e =>{
        let firstName = e.firstNameEncrypted === undefined ? "No firstname, " : e.firstNameEncrypted + ", ";
        let lastName = e.lastNameEncrypted === undefined ? "No lastname" :  e.lastNameEncrypted;
        userFilter[e.userId] = {text : firstName + " " + lastName, value : e.userId, selected : false};
    });
    return {users : userFilter};
    
}

const updateUsers = (payload, state) => {

    const updatedUserId = payload.userId;

    if (!updatedUserId) {
        return;
    }

    const oldUsers = state.users

    if (oldUsers) {
        const users = [
            ...oldUsers
        ]

        users.forEach(stateUser => {
            const stateUserId = stateUser.userId
            if (updatedUserId === stateUserId) {
                Object.assign(stateUser, payload);
            }
        })

        return { users }
    }
}

const getAuthData = (payload, derive) => {
    let user = null;
    let merchant = null;
    let users = null;
    let merchants = null;
    let partners = null;
    if (payload) {
        merchants = payload.merchants;
        users = payload.users;
        partners = payload.accounts;
        const userId = payload.submitPinUserId;
        const merchantId = payload.submitPinMerchantId;
        if (merchants) {
            if (Object.keys(merchants).length === 1) {
                merchant = Object.values(merchants)[0];
            } else if (derive || !!merchantId) {
                const id = !!merchantId ? merchantId : localStorage.getItem("merchant:id");
                if (id) {
                    merchant = merchants[id];
                }
            }
            if (merchant) {
                localStorage.setItem("merchant:id", merchant.id);
                setAmplitudeUserId(merchant.id);
                datadogRum.setUserProperty("merchantId", merchant.id);
            }
        }
        if (users) {
            if (merchants) {
                users.forEach(item => {
                    const merchant = merchants[item.merchantId];
                    if (merchant) {
                        item.merchantName = merchant.doingBusinessAsEncrypted || merchant.businessLegalName;
                        const partner = partners[merchant.accountId];
                        if (merchant.accountId && partner) {
                            item.transactionsEnabled = merchant.transactionsEnabled;
                            item.partnerId = merchant.accountId;
                            item.partnerAlias = partner.alias;
                            item.partnerName = partner.name ? partner.name : 'Wisetack';
                            item.discount = merchant.discount ? merchant.discount.toFixed(1) + '%' : ''
                            item.partnerMerchantPortalTransactionEnabled = partner.merchantPortalTransactionEnabled
                            item.merchantMerchantPortalTransactionEnabled = merchant.merchantPortalTransactionEnabled
                        }
                    }
                    item.merchantNames = getUserMerchantNames(item, merchants);
                })
            }

            if (users.length === 1) {
                user = users[0];
            } else if (derive || !!userId) {
                const id = !!userId ? userId : localStorage.getItem("merchant:user:id");
                if (id) {
                    users.forEach(item => {
                        if (item.userId === id) {
                            user = item;
                        }
                    })
                }
            }

            if (user) {
                localStorage.setItem("merchant:user:id", user.userId);
                datadogRum.setUserProperty("id", user.userId);
            }
        }
    }

    return { user, merchant, users, merchants, partners }
}

const getUserMerchantNames = (user, merchants) => {
    const userAccountId = user.accountId;
    let merchantNames;
    if (userAccountId) {
        merchantNames = new Set();
        Object.values(merchants).forEach(merchant => {
            const merchantAccountId = merchant.accountId
            if (userAccountId === merchantAccountId) {
                const merchantName = merchant.doingBusinessAsEncrypted || merchant.businessLegalName;
                if (merchantName) {
                    merchantNames.add(merchantName.trim())
                }
            }
        })
    }
    return merchantNames;
}

const deriveUser = (state, user) => {
    let merchant = null;
    if (state.merchants && user.merchantId) {
        merchant = state.merchants[user.merchantId];
        if (merchant) {
            localStorage.setItem("merchant:id", merchant.id);
        }
    }
    localStorage.setItem("merchant:user:id", user.userId);
    return { user, merchant }
}

const getCursorData = (payload) => {
    const cursorData = {
        cursorAfter: null
    }
    if (payload.cursor) {
        cursorData.cursorAfter = payload.cursor.afterCursorId;
    }
    return cursorData;
}

const getExportTxCursorData = (payload) => {
    const exportTxCursorData = {
        txExCursorAfter: null
    }
    if (payload.cursor) {
        exportTxCursorData.txExCursorAfter = payload.cursor.afterCursorId;
    }
    return exportTxCursorData;
}

const getPrequalCursorData = (payload) => {
    const cursorData = {
        prequalCursorAfter: null
    }
    if (payload.cursor) {
        cursorData.prequalCursorAfter = payload.cursor.afterCursorId;
    }
    return cursorData;
}

const getExportPreqCursorData = (payload) =>{
    
    const exportPreqCursorData = {
        preqExCursorAfter: null
    }
    if (payload.cursor) {
        exportPreqCursorData.preqExCursorAfter = payload.cursor.afterCursorId;
    }
    return exportPreqCursorData;
}

const filterTransactions = (transactions) => {
    if (Array.isArray(transactions)) {
        return transactions.filter(item => {
            return !!item.consumer && (!!item.consumer.shortName || !!item.consumer.phone)
        })
    }
    return transactions;
}

const preparePrequals = (state, prequals) => {
    let fetchedPrequalsNumber = 0;
    if (!!prequals) {
        fetchedPrequalsNumber = prequals.length
        prequals.forEach(item => {
            if (item.createdAt) {
                item.createdAt = moment.unix(item.createdAt / 1000).format("YYYY-MM-DD")
            }
        })
    } else {
        prequals = []
    }
    if (!!state.prequals) {
        prequals = state.prequals.concat(prequals)
    }
    return {prequals, fetchedPrequalsNumber};
}

const updatePrequals = (state, prequals) => {
    if (!prequals || prequals.length !== 1) {
        return {}
    }
    const prequal = prequals[0]
    if (prequal.createdAt) {
        prequal.createdAt = moment.unix(prequal.createdAt / 1000).format("YYYY-MM-DD")
    }
    if (!state.prequals || state.prequals.length === 0) {
        return { prequal, prequals: [prequal] }
    }
    let itemFound = false
    const updatedPrequals = [...state.prequals]
    updatedPrequals.forEach(item => {
        if (item.id === prequal.id) {
            Object.assign(item, prequal)
            itemFound = true
        }
    })
    if (!itemFound) {
        updatedPrequals.push(prequal)
    }
    return { prequal, prequals: updatedPrequals }
}

const prepareTransactions = (state, transactions) => {

    const filteredTransactions = filterTransactions(transactions);

    if (!!filteredTransactions && !!state.transactions) {
        return state.transactions.concat(filteredTransactions);
    }

    return filteredTransactions;
}

const cleanUpUserFromStore = (state, merchantId, userId) => {

    const merchantUsers = state.merchantUsers

    const oldUsers = merchantUsers[merchantId]

    if (!oldUsers) {
        return
    }

    const users = Object.assign({}, oldUsers)
    delete users[userId]

    merchantUsers[merchantId] = users

    return { merchantUsers }
}

const updateMerchantUser = (updatedUser, state) => {

    const merchantId = updatedUser.merchantId;
    if (!merchantId) {
        return
    }

    const updatedUserId = updatedUser.userId;

    if (!updatedUserId) {
        return
    }

    const merchantUsers = state.merchantUsers
    if (!merchantUsers) {
        return
    }

    const users = merchantUsers[merchantId]

    if (!users) {
        return
    }

    const userToUpdate = users[updatedUserId]

    if (!userToUpdate) {
        return
    }

    merchantUsers[merchantId][updatedUserId] = Object.assign({}, userToUpdate, updatedUser)

    return { merchantUsers }
}

const logOut = () => {
    sessionStorage.removeItem("wisetack:ba:token");
    localStorage.removeItem("merchant:username");
    localStorage.removeItem("merchant:id");
    localStorage.removeItem("merchant:user:id");
}

const merchantReducer = (state = initState, action) => {
    switch (action.type) {
        case API_REQUEST_STARTED:
            const apiRequests = {}
            if (action.payload && action.payload.requestId) {
                apiRequests[action.payload.requestId] = action.payload.requestType || true
            }
            return {
                ...state,
                isLoading: true,
                errorMessage: "",
                fieldsError: {},
                apiRequests: {
                    ...state.apiRequests,
                    ...apiRequests
                }
            };
        case API_REQUEST_ENDED:
            const requests = { ...state.apiRequests }
            if (action.payload && action.payload.requestId) {
                delete requests[action.payload.requestId]
            }
            return {
                ...state,
                isLoading: false,
                apiRequests: requests
            };
        case API_ERROR_RESPONSE:
            return {
                ...state,
                errorMessage: action.payload
            };
        case MERCHANT_API_ERROR:
            return {
                ...state,
                apiError: action.payload,
            };
        case CONSUMER_GET_VERTICAL_MESSAGES:
            return {
                ...state,
                messages: action.payload.messages
            };
        case MERCHANT_SUBMIT_EMAIL:
        case MERCHANT_SUBMIT_USERNAME:
            const username = action.payload.username
            if (username) {
                logOut();
                localStorage.setItem("merchant:username", username);
                return {
                    ...state,
                    username,
                    jwt: null,
                    submitUsernameRequestId: action.payload.submitRequestId
                }
            }
            return state;
        case MERCHANT_SUBMIT_PIN:
        case MERCHANT_SUBMIT_EMAIL_PIN:
            if (action.payload.token) {
                sessionStorage.setItem("wisetack:ba:token", action.payload.token);
            }
            if (action.payload.message && action.payload.type) {
                return {
                    ...state,
                    errorMessage: action.payload.message
                };
            }
            return {
                ...state,
                jwt: decodeToken(action.payload.token),
                submitPinRequestId: action.payload.submitPinRequestId,
                vertical: action.payload.vertical,
                ...getAuthData(action.payload)
            }
        case MERCHANT_GET_ME:
            if (action.payload.token) {
                sessionStorage.setItem("wisetack:ba:token", action.payload.token);
            }
            return {
                ...state,
                jwt: decodeToken(action.payload.token),
                getMerchantRequestId: action.payload.getMerchantRequestId,
                vertical: action.payload.vertical,
                ...getAuthData(action.payload, true),
                refreshMerchant: false
            }
        case MERCHANT_GET_TRANSACTIONS:
            return {
                ...state,
                jwt: action.payload.jwt || state.jwt,
                getTransactionsRequestId: action.payload.getTransactionsRequestId,
                transactions: prepareTransactions(state, action.payload.transactions),
                ...getCursorData(action.payload)
            }
        case MERCHANT_EXPORT_TRANSACTIONS:
            return {
                ...state,
                jwt: action.payload.jwt || state.jwt,
                getTransactionsRequestId: action.payload.getTransactionsRequestId,
                exportedTransactions: action.payload.transactions,
                ...getExportTxCursorData(action.payload)
            }
        case MERCHANT_GET_PREQUALS:
            return {
                ...state,
                jwt: action.payload.jwt || state.jwt,
                getPrequalsRequestId: action.payload.requestId,
                ...preparePrequals(state, action.payload.prequals),
                ...getPrequalCursorData(action.payload)
            }
        case MERCHANT_EXPORT_PREQUALS:
            return {
                ...state,
                jwt: action.payload.jwt || state.jwt,
                getPrequalsRequestId: action.payload.requestId,
                exportedPrequals: action.payload.prequals,
                ...getExportPreqCursorData(action.payload)
            }
        case MERCHANT_GET_PREQUAL:
            return {
                ...state,
                jwt: action.payload.jwt || state.jwt,
                getPrequalRequestId: action.payload.requestId,
                ...updatePrequals(state, action.payload.prequals) // one prequal should be updated
            }
        case MERCHANT_SET_TRANSACTION_DATA:
            return {
                ...state,
                transaction: action.payload
            }
        case MERCHANT_SET_PREQUAL_DATA:
            return {
                ...state,
                prequal: action.payload
            }
        case MERCHANT_GET_TRANSACTION:
            return {
                ...state,
                transaction: action.payload
            }
        case MERCHANT_SET_USER:
            return {
                ...state,
                ...deriveUser(state, action.payload),
                transactions: null,
                prequals: null,
            }
        case MERCHANT_CREATE_TRANSACTION:
            if (action.payload.message && action.payload.type) {
                return {
                    ...state,
                    errorMessage: action.payload.message
                };
            }
            const newTransaction = action.payload.newTransaction
            if (newTransaction) {
                ['initToken', 'transactionId', 'merchantName', 'status'].forEach(name => {
                    if (action.payload[name]) {
                        newTransaction[name] = action.payload[name]
                    }
                })
            }
            return {
                ...state,
                jwt: action.payload.jwt || state.jwt,
                createTransactionRequestId: action.payload.createTransactionRequestId,
                newTransaction,
                transactions: null
            }
        case MERCHANT_REMINDER:
            return {
                ...state,
                reminderList: action.payload.reminderList,
                reminderMessage: action.payload.reminderMessage,
                reminderRequestId: action.payload.reminderRequestId,
                reminderBlocked: action.payload.reminderBlocked
            }
        case APPLY_FILTER:
            return {
                ...state,
                filter: action.payload.filter,
                filterId: action.payload.filterId,
                transactions: null
            }
        case APPLY_PREQUAL_FILTER:
            return {
                ...state,
                prequalFilter: action.payload.filter,
                prequalFilterId: action.payload.filterId,
                prequals: null
            }
        case MERCHANT_LOG_OUT:
            logOut();
            return initState
        case SIGNUP_ZIP:
            return {
                ...state,
                checkedZip: action.payload
            }
        case SETTINGS_UPDATE_BUSINESS:
            return {
                ...state,
                ...updateBusinessData(action.payload, state)
            }
        case SETTINGS_UPDATE_USER:
            return {
                ...state,
                ...updateUsers(action.payload, state)
            }
        case MERCHANT_MANUAL_ACCOUNT_DATA:
            return {
                ...state,
                manualAccountData: action.payload
            }
        case UPDATE_MERCHANT_BANK_INFO:
            return {
                ...state,
                merchantUpdate: action.payload,
                refreshMerchant: true
            }
        case CREATE_LINK_TOKEN:
            return {
                ...state,
                linkTokens: { ...state.linkTokens, [action.payload.accountId]: action.payload.linkToken },
                linkTokenRequestId: action.payload.linkToken.requestId
            }
        case ACCEPT_PLAID_TOKEN:
            return {
                ...state,
                status: action.payload.status,
                plaidTokenAccepted: true,
                refreshMerchant: true,
                acceptPlaidTokenRequestId: action.payload.requestId
            }
        case MERCHANT_ADD_USER:
            return {
                ...state,
                addUserRequestId: action.payload.addUserRequestId,
                ...mergeMerchantUser(action.payload, state)
            }
        case MERCHANT_GET_USERS:
            return {
                ...state,
                ...mergeMerchantUsers(action.payload, state)
            }
        case DYNAMIC_USER_FILTER:
            return {
                ...state,
                filter : {...state.filter, ...extractUserFilter(action.payload, state)},
                prequalFilter : {...state.prequalFilter, ...extractUserFilter(action.payload, state)}
            }
        case MERCHANT_SET_USER_IN_EDIT:
            return {
                ...state,
                userInEdit: action.payload
            }
        case MERCHANT_DELETE_USER:
            return {
                ...state,
                deleteUserRequestId: action.payload.deleteUserRequestId,
                userInEdit: null,
                ...cleanUpUserFromStore(state, action.payload.merchantId, action.payload.userId)
            }
        case MERCHANT_UPDATE_USER_BY_ADMIN:
            return {
                ...state,
                updateMerchantUserRequestId: action.payload.updateMerchantUserRequestId,
                ...updateMerchantUser(action.payload, state)
            }
        case MERCHANT_NEW_PREQUAL:
            return {
                ...state,
                newPrequalRequestId: action.payload.requestId,
                prequalLink: action.payload.prequalLink,
                prequals: null
            }
        default:
            return state;
    }
}

export default merchantReducer;
