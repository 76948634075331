export const initStatuses = () =>  {
    return {
        STATUS_INITIATED: {text: "Initiated", description: "Initiated - application started", selected: false},
        STATUS_ACTION_REQUIRED: {text: "Action Required", description: "Action Required - loan incomplete", selected: false},
        STATUS_AUTHORIZED: {text: "Authorized", description: "Authorized - offers available", selected: false},
        STATUS_ACCEPTED: {text: "Accepted", description: "Accepted - loan terms signed", selected: false},
        STATUS_CONFIRMED: {text: "Confirmed", description: "Confirmed - loan confirmed", selected: false},
        STATUS_SETTLED: {text: "Settled", description: "Settled - funds sent", selected: false},
        STATUS_DECLINED: {text: "Declined", description: "Declined - application declined", selected: false},
        STATUS_EXPIRED: {text: "Expired", description: "Expired - loan expired", selected: false},
        STATUS_CANCELED: {text: "Canceled", description: "Canceled - loan canceled", selected: false}
    }
}

export const initPrequalStatuses = () => {
    return {
        STATUS_INITIATED: {text: "Initiated", selected: false},
        STATUS_PREQUALIFIED: {text: "Prequalified", selected: false},
        STATUS_DECLINED: {text: "Declined", selected: false},
        STATUS_EXPIRED: {text: "Expired", selected: false},
        STATUS_CANCELED: {text: "Canceled", selected: false}
    }
}

export const initYears = () => {
    let years = {}
    let year = new Date().getFullYear()
    while (year > 2018) {
        const key = "YEAR_" + year
        years[key] = {
            text: String(year),
            selected: false
        }
        year --
    }
    return years
}

export const initMonths = () => {
    return {
        MONTH_JANUARY: {text: "January", value: "01", selected: false},
        MONTH_FEBRUARY: {text: "February", value: "02", selected: false},
        MONTH_MARCH: {text: "March", value: "03", selected: false},
        MONTH_APRIL: {text: "April", value: "04", selected: false},
        MONTH_MAY: {text: "May", value: "05", selected: false},
        MONTH_JUNE: {text: "June", value: "06", selected: false},
        MONTH_JULY: {text: "July", value: "07", selected: false},
        MONTH_AUGUST: {text: "August", value: "08", selected: false},
        MONTH_SEPTEMBER: {text: "September", value: "09", selected: false},
        MONTH_OCTOBER: {text: "October", value: "10", selected: false},
        MONTH_NOVEMBER: {text: "November", value: "11", selected: false},
        MONTH_DECEMBER: {text: "December", value: "12", selected: false}
    }
}

export const getResourcesURL = (alias) => {
    switch (alias) {
        case "hcp":
            return "https://wisetack.com/hcp-welcome"
        case "jobber":
            return "https://wisetack.com/jobber-welcome"
        case "iasap":
            return "https://wisetack.com/iasap-welcome"
        case "homeservices":
            return "https://wisetack.com/homeservices-welcome"
        case "yelp":
            return "https://wisetack.com/yelp-welcome";
        default:
            return "https://wisetack.com/"+encodeURIComponent(alias.toLowerCase())+"-welcome"
    }
}
