import { connect } from "react-redux";
import NavBar from "../components/NavBar";
import Container from "../components/Container";
import React from "react";
import building_img from "@wisetack/assets/img/building_color.svg"
import styles from "./BusinessPage.module.scss"
import ButtonOutlined from "../components/ButtonOutlined";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { appTransition, redirectToEntryPage } from "../utils/transitions";
import { stateFromProps } from "../utils/state";
import { logOut, getMerchant, setMerchantUser, setError } from "../store/actions/merchantActions";
import { isAdmin, verifyWisetackToken } from "../utils/auth";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import { formatUsPhoneToDomestic } from "@wisetack/shared-ui/src/utils/format";
import Error from "@wisetack/shared-ui/components/Error";
import { formatFEIN } from "../utils/format";
import checkmark from "@wisetack/assets/img/checkmark-icon.svg"
import info from "@wisetack/assets/img/info-light.svg"
import infoBig from "@wisetack/assets/img/info.svg"
import Modal from "@wisetack/shared-ui/components/Modal";

const stateNames = [
    "users",
    "merchant",
    "user",
    "isLoading",
    "refreshMerchant",
    "errorMessage"
]

const MdrPricingPlans = {
    FLAT: 'FLAT',
    DYNAMIC: 'DYNAMIC'
}

const pageName = "Merchant information Page"
const logProps = { page: pageName }

class BusinessPage extends React.Component {

    state = {}

    handleEdit = () => {
        this.props.history.push('/business_edit')
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        this.props.setError(null)
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        appTransition({ alias: 'account_settings' }, this.props)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (this.props.refreshMerchant) {
            this.props.getMerchant(this.props.merchant.id);
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);
        this.props.setError(null)
    }

    render() {

        const isAdminRole = isAdmin(this.props.user);

        let formattedFEIN
        let formattedPhone
        let cityStateAndZip
        let mdr
        let mdrDynamicText

        if (this.props.merchant) {
            formattedFEIN = formatFEIN(this.props.merchant.federalEIN)
            formattedPhone = formatUsPhoneToDomestic(this.props.merchant.phoneNumber)
            cityStateAndZip = this.props.merchant.city + ", " + this.props.merchant.state + " " + this.props.merchant.zip
            mdr = this.props.merchant.mdrPricing
            if (mdr !== undefined && mdr.plan === MdrPricingPlans.DYNAMIC) {
                mdrDynamicText = "Extended 0% APR - " + mdr.months.join(", ") + " mo  "
            }
        }

        return (
            <Container>
                <NavBar title={"Merchant information"}
                    users={this.state.users}
                    onMenuItemClick={this.handleMenuItemClick}
                    onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    <div className={styles.title}>Your business profile</div>
                    <img src={building_img} alt={"building_color.svg"} />
                </div>
                <LoaderWithMessage loading={this.props.isLoading} />
                <Error pageName={pageName}>{this.props.errorMessage}</Error>
                {!this.props.isLoading && this.props.merchant &&
                    <div className={styles.content}>
                        <div className={styles.name_ico}>
                            <span className="material-icons-outlined">account_circle</span>
                        </div>
                        <div className={styles.name}>
                            {this.props.merchant.businessLegalName}<br />
                            {this.props.merchant.doingBusinessAsEncrypted}
                        </div>
                        <div className={styles.street_ico}>
                            <span className="material-icons-outlined">home</span>
                        </div>
                        <div className={styles.street}>
                            {this.props.merchant.businessAddress}<br />
                            {cityStateAndZip}
                        </div>
                        <div className={styles.phone_ico}>
                            <span className="material-icons-outlined">phone</span>
                        </div>
                        <div className={styles.phone}>
                            {formattedPhone}
                        </div>
                        <div className={styles.email_ico}>
                            <span className="material-icons-outlined">email</span>
                        </div>
                        <div className={styles.email}>
                            {this.props.merchant.email}
                        </div>
                        {isAdminRole &&
                            <>
                                <div className={styles.ein_ico}>
                                    <span className="material-icons-outlined">picture_in_picture</span>
                                </div>
                                <div className={styles.ein}>
                                    {"Federal EIN: " + formattedFEIN}
                                </div>
                                <div className={styles.acc_ico}>
                                    <span className="material-icons-outlined">account_balance</span>
                                </div>
                                <div className={styles.acc}>
                                    {`${this.props.merchant.paymentAccount.accountNameEncrypted}: ${this.props.merchant.paymentAccount.accountNumberEncrypted}`}
                                </div>
                            </>
                        }

                        {mdr && Object.values(MdrPricingPlans).includes(mdr.plan) &&
                            <>
                                <div className={styles.mdr_ico}>
                                    <img src={checkmark} alt="checkmark-icon.svg"/>
                                </div>
                                <div className={styles.mdr}>
                                    <div className={styles.mdr}>{"Standard " + mdr.percentage + "% fee"}</div>
                                    {mdr && mdr.plan === MdrPricingPlans.DYNAMIC &&
                                        <> <span>{mdrDynamicText}</span>
                                            <img src={info} alt="info-light.svg"
                                                 data-toggle="modal"
                                                 data-target="#mdrDynamicModal"/>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                }

                {!this.props.isLoading && isAdminRole &&
                    <ButtonOutlined onClick={this.handleEdit}>EDIT</ButtonOutlined>
                }

                {mdr && mdr.plan === MdrPricingPlans.DYNAMIC &&
                    <Modal id="mdrDynamicModal">
                        <div><img className={styles.modalImage} src={infoBig} alt="infoBig"/></div>
                        <div className={styles.modalHeader}><span>Extended 0% APR</span></div>
                        <p className={styles.modalParagraph}>You will only be charged the fee listed here when a
                            customer chooses an extended 0% APR offer.</p>
                        <p className={styles.modalSubParagraph}>Extended 0% APR pricing plan</p>
                        <span className={styles.modalBlueHorizontalBar}>6 mo</span>
                        <span className={styles.modalPercentage}>4.99% fee</span>
                        {mdr.months.includes(12) &&
                            <div>
                                <span className={styles.modalOrangeHorizontalBar}>12 mo</span>
                                <span className={styles.modalPercentage}>6.99% fee</span>
                            </div>
                        }
                        {mdr.months.includes(24) &&
                            <div>
                                <span className={styles.modalRedHorizontalBar}>24 mo</span>
                                <span className={styles.modalPercentage}>9.99% fee</span>
                            </div>
                        }
                    </Modal>
                }
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        setMerchantUser,
        setError
    }
)(BusinessPage);