import React from "react";
import classNames from "classnames";
import styles from "./CustomerInformation.module.scss";
import ownStyles from "./TransactionDetails.module.scss";
import {formatExpires} from "../utils/format";
import {getOfferInfo} from "../utils/offerInfo";

const totalRefunds = (item) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    let refundsAmmount = 0;

    if (!item.refunds || item.refunds.length === 0)
        return null;

    item.refunds.forEach(refund => {
        refundsAmmount += parseFloat(refund.amount.replace(/[$|,]/g, ""));
    })
    return formatter.format(refundsAmmount);
}

export function TransactionDetails({item}) {
    const approved = item.status === "Initiated"
        ? "Pending Approval" : (item.approvedLoanAmount || "N/A")
    const requested = item.requestedLoanAmount || "N/A"
    const qualified = item.maximumAmount || "N/A"
    const netProceeds = item.settledLoanAmount || "N/A"
    const expires = item.expiresAt ? `Expires ${formatExpires(item.expiresAt)}` : "Expires N/A"
    const refundsAmmount = totalRefunds(item);
    const offers = getOfferInfo(item.offers)

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse show": true,
        [styles.cardBody]: true
    });

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    aria-expanded="true"
                    href="#collapseTransaction"
                >
                    <div className={styles.header}>
                        Transaction details
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseTransaction" className={cardBodyClasses}>
                <div className={classNames(ownStyles.descrWrapper)}>
                    <div className={classNames(ownStyles.creditCard, styles.info)}>
                        <span className="material-icons-outlined">credit_card</span>
                    </div>
                    <div className={classNames(ownStyles.approved, styles.info)}>Approved</div>
                    <div className={classNames(ownStyles.approvedValue, styles.info)}>{approved}</div>
                    <div className={classNames(ownStyles.requested, styles.info)}>Requested</div>
                    <div className={classNames(ownStyles.requestedValue, styles.info)}>{requested}</div>
                    <div className={classNames(ownStyles.qualified, styles.info)}>Qualified</div>
                    <div className={classNames(ownStyles.qualifiedValue, styles.info)}>{qualified}</div>
                    <div className={classNames(ownStyles.netProceeds, styles.info)}>Net proceeds</div>
                    <div className={classNames(ownStyles.netProceedsValue, styles.info)}>{netProceeds}</div>
                    {refundsAmmount != null && <div className={classNames(ownStyles.refunds, styles.info)}>Refunded</div>}
                    {refundsAmmount != null && <div className={classNames(ownStyles.refundsValue, styles.info)}>{refundsAmmount}</div>}
                    {offers && offers.terms && <div className={classNames(ownStyles.terms, styles.info)}>Offers available</div>}
                    {offers && offers.terms && <div className={classNames(ownStyles.termsValue, styles.info)}>{offers.terms.apr}<br/>{offers.terms.months}</div>}
                    {offers && <div className={classNames(ownStyles.mdrs, styles.info)}>Processing fee</div>}
                    {offers && <div className={classNames(ownStyles.mdrsValue, styles.info)}>{offers.mdrs}</div>}
                    <div className={classNames(ownStyles.dateRange, styles.info)}>
                        <span className="material-icons-outlined">date_range</span>
                    </div>
                    <div className={classNames(ownStyles.expires, styles.info)}>{expires}</div>
                </div>
            </div>
        </div>
    )
}