import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import '@wisetack/assets/material-kit/scss/material-kit.scss'
import Log from '@wisetack/shared-ui/utils/Log';

if (process.env.NODE_ENV !== 'production') {
    localStorage.setItem('debug', 'wisetack:*');
    Log.info(process.env.NODE_ENV, `NODE_ENV`);
}

ReactDOM.render(<Provider store={store}><HashRouter><App /></HashRouter></Provider>, document.getElementById('root'));

if (window.Cypress) {
    Log.info('Exposing store during Cypress tests', `Cypress`);
    window.reduxStore = store
    window.wisetackApiUrl = window._wtenv_?.REACT_APP_API_URL
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
