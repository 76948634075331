import React from "react";
import {connect} from "react-redux";

import {getMerchant, getTransactions, getPrequals} from "../store/actions/merchantActions";
import useAppFlow from "../hooks/useAppFlow";
import Container from "../components/Container";
import NavBar from "../components/NavBar";
import {Option} from "../components/Option";
import {useHistory} from "react-router";
import styles from "./TransactionMenuPage.module.scss";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";

const pageName = 'Transaction Menu Page'

function TransactionMenuPage({getMerchant, getTransactions, getPrequals}) {
    const history = useHistory();

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');
    const {user, users, handleMenuItemClick, logAmplitude} = useAppFlow({getMerchant, newRequest}, pageName)
    const loading = !!requestId && requestInProgress

    const onBackArrowClick = () => {
        logAmplitude( 'Back button clicked')
        if (users && users.length > 1) {
            history.push('/accounts')
            return
        }
        history.push('/')
    }

    return <Container>
        <NavBar users={users}
                onMenuItemClick={handleMenuItemClick}
                onBackArrowClick={onBackArrowClick}
        />
        <LoaderWithMessage loading={loading}/>
        { !loading && <>
            <div className={styles.merchant}>{user ? user.merchantName : ''}</div>
            <div data-test-id="options">
                <Option onClick={() => history.push('/prequals')}><div className={styles.option}>Prequalifications</div></Option>
                <Option onClick={() => history.push('/transactions')}><div className={styles.option}>Transactions</div></Option>
            </div>
            <Error>{requestError}</Error>
        </>
        }
    </Container>
}

export default connect(null, {getMerchant, getTransactions, getPrequals})(
    TransactionMenuPage
);
