import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";

import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Error from "@wisetack/shared-ui/components/Error";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import PlaidLink from "@wisetack/shared-ui/components/PlaidLink";
import useApiRequest from "@wisetack/shared-ui/utils/useApiRequest";

import {Loader} from "../components/Loader";
import Container from "../components/Container";

import {
    submitPin,
    getMerchant,
    createLinkToken,
    acceptPlaidToken
} from "../store/actions/merchantActions";
import useAppFlow from "../hooks/useAppFlow";
import {customizeErrorMessage} from "../utils/format";
import styles from "./LinkBankPlaidPage.module.scss";

const pageName = "Link Bank Account"

function LinkBankPlaidPage({getMerchant, submitPin, createLinkToken, acceptPlaidToken}) {
    const history = useHistory();

    const errorMessage = useSelector(state => state.merchant.errorMessage);
    const submitPinRequestId = useSelector(state => state.merchant.submitPinRequestId);
    const linkTokenRequestId = useSelector(state => state.merchant.linkTokenRequestId);
    const acceptPlaidTokenRequestId = useSelector(state => state.merchant.acceptPlaidTokenRequestId);
    const linkTokens = useSelector(state => state.merchant.linkTokens);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');

    const [pin, setPin] = useState("");
    const [suggestions, setSuggestions] = useState({});
    const [authenticated, setAuthenticated] = useState(false);
    const [linkLoading, setLinkLoading] = useState(false)
    const [linkToken, setLinkToken] = useState("");
    const [error, setError] = useState("")

    const loading = (!!requestId && requestInProgress) || linkLoading

    const {user, merchant, logAmplitude} = useAppFlow({getMerchant, newRequest}, pageName)

    const combinedError = requestError || errorMessage
    const pinVerified = !!requestId && requestId === submitPinRequestId && user && merchant
    const linkTokenCreated = !!requestId && requestId === linkTokenRequestId && linkTokens
    const plaidTokenAccepted = !!requestId && requestId === acceptPlaidTokenRequestId

    useEffect(() => {
        if (pinVerified) {
            logAmplitude("Email pin verification succeed")
            setAuthenticated(true)
            createLinkToken(merchant.id, null, newRequest());
            setLinkLoading(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pinVerified])

    useEffect(() => {
        if (linkTokenCreated && linkTokens.flexible) {
            logAmplitude("Plaid link token created")
            setLinkToken(linkTokens.flexible.token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkTokenCreated])

    useEffect(() => {
        if (plaidTokenAccepted) {
            logAmplitude("Plaid bank account linked")
            history.push("/bank_link_success")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plaidTokenAccepted])

    useEffect(() => {
        if (combinedError) {
            const custError = customizeErrorMessage(combinedError)
            if (custError && custError.pin) {
                setSuggestions(custError)
                logAmplitude("Pin error", {pinError: custError})
                return
            } else {
                setSuggestions({})
            }
            logAmplitude("Error message", {errorMessage: combinedError})
            if (combinedError.includes('lockout')) {
                history.push("/account_locked");
                return
            }
            setError(combinedError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [combinedError])

    const handleOnChange = (e) => {
        let val = e.target.value;
        val = val.replace(/\D/g, "");
        if (val.length > 4) {
            return;
        }
        setPin(val)
        if (val.length === 4) {
            submitPin(val, user.emailEncrypted, user.userId, merchant.id, newRequest())
            logAmplitude("Email pin submitted", {pin: val})
        }
    }

    const handleOnButtonClick = () => {
        history.push("/");
    }

    const handleOnBlur = () => {}

    const handleOnLoad = () => {
        logAmplitude("Plaid link client loaded")
        setLinkLoading(false)
    }

    const handleOnExit = (err, metadata) => {
        logAmplitude("Plaid Link Exit", {err, metadata})
        history.push("/link_bank_mode");
    }

    const handleOnEvent = (eventName, metadata) => {
        logAmplitude("Plaid Link Event", {eventName, metadata})
    }

    const handleOnSuccess = (token, metadata) => {
        let accounts = [];
        if (metadata.accounts) {
            accounts = metadata.accounts.map(acc => {
                return {
                    id: acc.id,
                    mask: acc.mask,
                    name: acc.name,
                    type: acc.type,
                    subtype: acc.subtype,
                    verificationStatus: acc.verification_status || ''
                }
            });
        }
        logAmplitude("Plaid Link Success", {accounts, metadata})
        acceptPlaidToken(user.merchantId, token, accounts, newRequest())
    }

    return (
        <Container>
            { !authenticated ? <PageHeader progress="">
                <div>We just emailed you</div>
                <div style={{paddingLeft: "15px", textAlign: "center"}}>
                    Please enter the verification code we sent to the email address on file to update your bank
                    account information
                </div>
            </PageHeader> : <PageHeader progress="">
                <div>Linking bank account</div>
                <div style={{paddingLeft: "15px", textAlign: "center"}}>
                    Wait a moment please...
                </div>
            </PageHeader>
            }
            <PlaidLink
                hidden={true}
                clientName="Wisetack"
                token={linkToken}
                env={window._wtenv_?.REACT_APP_PLAID_ENV}
                onLoad={handleOnLoad}
                onExit={handleOnExit}
                onEvent={handleOnEvent}
                onSuccess={handleOnSuccess}
                handlerName="linkPlaidRegularHandler"
            />
            { loading ? <Loader/> : <>
                { !authenticated && <>
                    <div className={styles.inputs}>
                        <FormRow>
                            <FormInput
                                autoFocus
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="pin"
                                label="Enter the 4-digit code"
                                value={pin}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                suggestions={suggestions}
                            />
                        </FormRow>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className={styles.text_button}
                                onClick={handleOnButtonClick}
                            >
                                Didn't get a code?
                            </div>
                        </div>
                    </div>
                </>
                }
                <Error>{error}</Error>
            </>
            }
        </Container>
    )
}

export default connect(null, {submitPin, getMerchant, createLinkToken, acceptPlaidToken})(
    LinkBankPlaidPage
);
