import {connect} from "react-redux";
import NavBar from "../components/NavBar";
import Container from "../components/Container";
import React from "react";
import styles from "./BusinessEditPage.module.scss"
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {appTransition, redirectToEntryPage} from "../utils/transitions";
import {stateFromProps} from "../utils/state";
import {
    logOut,
    getMerchant,
    setMerchantUser,
    updateBusiness,
    setError,
    submitUsername
} from "../store/actions/merchantActions";
import {isAdmin, verifyWisetackToken} from "../utils/auth";
import Log from "@wisetack/shared-ui/utils/Log";
import ButtonFilled from "../components/ButtonFilled";
import ModalWrapper from "../components/ModalWrapper"
import FormInput from "@wisetack/shared-ui/components/FormInput";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import {BusinessEditFieldValidator} from "../utils/BusinessEditFieldValidator";
import {formatUsPhoneToDomestic, formatMobileNumberForApi} from "@wisetack/shared-ui/utils/format";
import bank_icon from "@wisetack/assets/img/bank_icon.svg";
import Modal from "@wisetack/shared-ui/components/Modal";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import info from "@wisetack/assets/img/info-icon.svg";
import edit from "../assets/img/edit.svg"
import classNames from "classnames";
import uuid from "uuid";

const merchantStateNames = [
    "users",
    "merchant",
    "user",
    "errorMessage",
    "isLoading"
]

const settingsStateNames = [
    "fieldUpdateStatus"
]

const updatedFieldToAmplitudeEventMapping = {
    phoneNumber: "Verify New Phone Page",
    email: "Verify New Email Page"
}

const pageName = "Merchant information edit page"
const logProps = {page: pageName}
const validator = new BusinessEditFieldValidator(pageName);

const setValidators = (props) => {
    validator.props = props
    for (const value of Object.values(formFields)) {
        if (value.validatorName) {
            value.validator = validator[value.validatorName];
        }
    }
}

const formFields = {
    phoneNumber: {
        label: 'Phone number',
        inputMode: 'numeric',
        pattern: '[0-9]*',
        autoFormat: formatUsPhoneToDomestic,
        validatorName: 'validateMobileNumber',
        hintLabel: 'You’ll receive a PIN code to this phone number for verification.',
        innerRef: React.createRef(),
        payloadFormat: formatMobileNumberForApi,
        verificationTitle: 'Let\'s verify your new number'
    },
    email: {
        label: 'Email address',
        validatorName: 'validateEmail',
        suggestFn: function (suggestion) {
            this.setState({suggestions: {email: suggestion}});
        },
        hintLabel: 'You’ll receive a PIN code to this email for verification.',
        innerRef: React.createRef(),
        payloadFormat: v => v,
        verificationTitle: 'Let\'s verify your new email address'
    },
    bankAccount: {
        label: 'Bank account',
        readOnly: true
    },
    bankAccountName: {},
    bankAccountNumber: {}
}

const MODE = {
    EDIT: 'EDIT',
    VERIFICATION: 'VERIFICATION'
}

class BusinessEditPage extends React.Component {

    state = {
        originalValues: {
            phoneNumber: formatUsPhoneToDomestic(this.props.phoneNumber),
            email: this.props.email,
        },
        errors: {},
        phoneNumber: formatUsPhoneToDomestic(this.props.phoneNumber),
        email: this.props.email,
        bankAccount: this.props.merchant.paymentAccount.accountNameEncrypted + ' - ' + this.props.merchant.paymentAccount.accountNumberEncrypted,
        showHintMessageFor: {},
        showSaveButtonFor: '',
        showEditButtonFor: Object.keys(formFields)
            .reduce((acc, key) => {
                return {...acc, [key]: true}
            }, {}),
        mode: MODE.EDIT,
        accessCode: ''
    }

    preparePayload = () => {
        const changedFieldName = this.state.showSaveButtonFor;
        return {
            [changedFieldName]: formFields[changedFieldName].payloadFormat(this.state[changedFieldName])
        }
    }

    handleSave = () => {

        const merchantId = this.props.user.merchantId;

        const payload = this.preparePayload()

        this.props.updateBusiness(merchantId, payload)

        this.switchToMode(MODE.VERIFICATION)

        const updatedFieldName = Object.keys(payload)[0];

        if (updatedFieldName) {
            const amplitudeEvent = updatedFieldToAmplitudeEventMapping[updatedFieldName];
            if (amplitudeEvent) {
                const verifyPageProps = {
                    ...logProps,
                    page: null
                }
                logAmplitudeEvent(amplitudeEvent, verifyPageProps)
            }
        }
    }

    wasModified = (inputName) => {

        const originalValue = this.state.originalValues[inputName];
        const actualValue = this.state[inputName];

        return originalValue !== actualValue
    }

    inputCleanUp = (name, val) => {
        if (val && formFields[name] && formFields[name].blacklist) {
            return val.replace(formFields[name].blacklist, "");
        }
        return val;
    }

    autoFormat = (name, val) => {
        if (!!val && typeof formFields[name].autoFormat === "function") {
            return formFields[name].autoFormat(val);
        }
        return val;
    }

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;

        val = this.inputCleanUp(name, val)
        val = this.autoFormat(name, val)
        this.setState({
            [name]: val,
        });

        this.setError(name, "");

        const wasModified = this.state.originalValues[name] !== val;

        if (wasModified) {
            this.showSaveButtonFor(name, true)
        } else {
            this.showSaveButtonFor(name, false)
        }
    };

    validateField = (name, val) => {
        if (typeof formFields[name].validator === "function") {
            if (formFields[name].suggestFn) {
                return formFields[name].validator(val, formFields[name].suggestFn.bind(this));
            } else {
                return formFields[name].validator(val);
            }
        }
        if (!val && !formFields[name].optional) {
            const message = formFields[name].mandatory || `Enter ${formFields[name].label}`
            validator.log(formFields[name].label, message);
            return message;
        }
        return "";
    };

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        if (this.isCurrentMode(MODE.EDIT)) {
            appTransition({alias: 'business'}, this.props)
        } else if (this.isCurrentMode(MODE.VERIFICATION)) {
            this.switchToMode(MODE.EDIT)
        }
    }

    formatMobileNumber = (mobileNumber) => {
        mobileNumber = mobileNumber.replace(/\D/g, "");
        if (mobileNumber.length === 10) {
            return "+1" + mobileNumber;
        } else if (mobileNumber.length === 11) {
            return "+" + mobileNumber;
        }
        return mobileNumber;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const userChanged = prevProps.user
            && this.props.user
            && prevProps.user.merchantId !== this.props.user.merchantId

        if (userChanged) {
            this.props.history.push("/business");
            return
        }

        if (this.isCurrentMode(MODE.VERIFICATION)) {
            let modifiedFieldName = this.state.showSaveButtonFor;
            if (!modifiedFieldName) {
                return
            }
            const fieldWasSuccessfullyUpdatedPrev = prevProps.fieldUpdateStatus.business[modifiedFieldName];
            const fieldWasSuccessfullyUpdated = this.props.fieldUpdateStatus.business[modifiedFieldName];
            if (!fieldWasSuccessfullyUpdatedPrev && fieldWasSuccessfullyUpdated) {
                this.props.history.push("/business");
            }
        }
    }

    componentDidMount() {

        const isAdminRole = isAdmin(this.props.user)
        if (!isAdminRole) {
            Log.error('Access denied for non admin user')
            this.props.history.push("/business");
        }

        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);

        setValidators(logProps)
        this.props.setError(null)
    }

    handleOnBlur = (e) => {
        let val = e.target.value;
        let name = e.target.name;

        const error = this.validateField(name, val);
        this.setError(name, error);

        if (error) {
            this.showSaveButtonFor(name, false)
        }
        this.showHintMessageFor(name, false)

        if (!this.wasModified(name)) {
            this.showEditButtonFor(name, true)
        }
    }

    setError = (name, error) => {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    getValue = name => {
        if (this.state[name]) {
            return this.state[name];
        }
        return "";
    };

    handleLinkBankAccountClick = async () => {
        logAmplitudeEvent("Pressed LinkBankAccount Button", logProps)
        await new Promise(r => setTimeout(r, 200));
        this.submitEmailForPin(this.props.user.emailEncrypted)
        this.props.history.push("/link_bank_plaid");
    };

    handleOnFocus = e => {
        let name = e.target.name;
        this.showHintMessageFor(name, true)
        if (this.wasModified(name)) {
            this.showSaveButtonFor(name, true)
        }
        this.showEditButtonFor(name, false)
        const focusProps = {
            ...logProps,
            fieldName: name
        }
        logAmplitudeEvent("Pressed edit button", focusProps);

    }

    showSaveButtonFor = (inputName, display) => {
        if (this.isReadOnly(inputName)) {
            return
        }
        const fieldName = display ? inputName : ''
        this.setState({
            showSaveButtonFor: fieldName
        })
    }

    showEditButtonFor = (inputName, display) => {
        if (this.isReadOnly(inputName)) {
            return
        }
        this.setState({
            showEditButtonFor: {
                ...this.state.showEditButtonFor,
                [inputName]: display
            }
        })
    }

    isReadOnly = fieldName => {
        return formFields[fieldName].readOnly;
    }

    showHintMessageFor = (fieldName, showMessage) => {
        if (this.isReadOnly(fieldName)) {
            return
        }
        this.setState(
            {
                showHintMessageFor: {
                    [fieldName]: showMessage
                }
            }
        )
    }

    isCurrentMode = (mode) => {
        return this.state.mode === mode;
    }

    switchToMode = (mode) => {
        this.setState({mode: mode})
    }


    handleOnAccessCodeChange = e => {
        let val = e.target.value
        let name = e.target.name

        val = val.replace(/\D/g, "")
        if (val.length > 4) {
            return;
        }

        this.setState({accessCode: val})
        this.setError(name, "")
        if (val.length === 4) {
          const merchantId = this.props.user.merchantId;
          const payload = this.preparePayload();
          payload["fieldUpdateAccessCode"] = val;
          this.props.updateBusiness(merchantId, payload);
        }
    }

    handleOnPenClick = e => {
        let name = e.target.name;
        let innerRef = formFields[name].innerRef;
        if (innerRef) {
            innerRef.current.focus();
        }
        this.showHintMessageFor(name, true)
        if (this.wasModified(name)) {
            this.showSaveButtonFor(name, true)
        }
        this.showEditButtonFor(name, false)

        const clickLogProps = {
            ...logProps,
            fieldName: name
        }
        logAmplitudeEvent("Pressed edit button", clickLogProps);
    }

    submitEmailForPin = (email) => {
        const requestId = uuid.v4();
        logAmplitudeEvent("Submitting email for pin", logProps);
        this.props.submitUsername(
            email,
            requestId,
            false
        );
    };

    render() {

        const textButtonClasses = classNames({
            [styles.text_button]: true
        });

        const formInput = (fieldName) => {

            return (
                <>
                    <FormInput
                        name={fieldName}
                        label={formFields[fieldName].label}
                        value={this.getValue(fieldName)}
                        onChange={this.handleOnChange}
                        onFocus={this.handleOnFocus}
                        onBlur={this.handleOnBlur}
                        errors={this.state.errors}
                        inputMode={formFields[fieldName].inputMode}
                        pattern={formFields[fieldName].pattern}
                        innerRef={formFields[fieldName].innerRef}
                        fieldsError={this.props.fieldsError}
                        suggestions={this.state.suggestions}
                        readOnly={formFields[fieldName].readOnly}
                        hintLabel={this.state.showHintMessageFor[fieldName] ? formFields[fieldName].hintLabel : ''}
                    />
                    {this.state.showEditButtonFor[fieldName] &&
                    <img name={fieldName} src={edit} alt={"edit.svg"} onClick={this.handleOnPenClick}/>
                    }
                    {this.state.showSaveButtonFor === fieldName &&
                    <ButtonFilled onClick={this.handleSave}>SAVE CHANGES</ButtonFilled>
                    }
                </>
            )
        }

        return (
            <Container>
                <NavBar title={"Merchant information"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    {this.isCurrentMode(MODE.EDIT) &&
                    <div className={styles.title}>Edit the fields below to update your business information</div>
                    }
                    {this.isCurrentMode(MODE.VERIFICATION) && !this.props.isLoading &&
                    <>
                        <div className={styles.title}>{formFields[this.state.showSaveButtonFor].verificationTitle}</div>
                        <div className={styles.subtitle}>Please enter the verification code we sent
                            to <b>{this.state[this.state.showSaveButtonFor]}</b></div>
                    </>
                    }
                </div>
                {this.isCurrentMode(MODE.EDIT) &&
                <>
                    <div className={styles.content}>
                        <FormRow>{formInput('phoneNumber')}</FormRow>
                        <FormRow>{formInput('email')}</FormRow>
                        <ModalWrapper showModal={true} modalId={'#changingBankAccountModal'}>
                            <FormRow >{formInput('bankAccount')}</FormRow>
                        </ModalWrapper>
                        <Modal id={'changingBankAccountModal'}>
                            <div className={styles.modal_text}>
                                <img className={styles.logo} src={bank_icon} alt="bank_icon.svg"/>
                                <h4>Changing bank account</h4>
                                <div>
                                    Use the link below to update your bank account information.
                                    <div
                                        className={textButtonClasses}
                                        data-dismiss="modal"
                                        onClick={this.handleLinkBankAccountClick}
                                    >
                                        Link bank account
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div className={styles.support}>
                        Need to make other changes? Please contact us at <b><a
                        href="mailto:support@wisetack.com">support@wisetack.com</a></b> for assistance.
                    </div>
                </>
                }

                {this.isCurrentMode(MODE.VERIFICATION) &&
                <>
                    <LoaderWithMessage loading={this.props.isLoading}/>
                    <Error pageName={pageName}>{this.props.errorMessage}</Error>
                    {!this.props.isLoading &&
                    <>
                        <FormRow>
                            <FormInput name='accessCode' inputMode='numeric' pattern="[0-9]*" label='4-digit code'
                                       value={this.state.accessCode} onChange={this.handleOnAccessCodeChange}
                                       errors={this.state.errors} fieldsError={this.props.fieldsError}
                            />
                        </FormRow>
                        <div data-toggle="modal" data-target="#getCodeModal">
                            <div className={styles.text_button}>
                                Didn't get a code?
                            </div>
                        </div>
                        <Modal id={'getCodeModal'}>
                            <div className={styles.modal_text}>
                                <img className={styles.logo} src={info} alt="info.svg"/>
                                <h4>Didn't get a code?</h4>
                                <div>
                                    Check and make sure the information you entered is correct. Then resubmit your
                                    changes
                                    to receive a new verification code.
                                </div>
                            </div>
                        </Modal>
                    </>
                    }
                </>
                }

            </Container>
        )
    }
}

const setPropFromMerchantState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const setPropFromSettingsState = (props, state, name, path) => {
    if (path) {
        state = state.settings[path];
    } else {
        state = state.settings
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};

    Object.keys(formFields).forEach(name => setPropFromMerchantState(props, state, name, 'merchant'))
    merchantStateNames.forEach(name => setPropFromMerchantState(props, state, name));
    settingsStateNames.forEach(name => setPropFromSettingsState(props, state, name))
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        setMerchantUser,
        updateBusiness,
        setError,
        submitUsername
    }
)(BusinessEditPage);