import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import uuid from "uuid";

import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import { MerchantFieldValidator } from "@wisetack/shared-ui/utils/MerchantFieldValidator";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { setAmplitudeUserProperty } from "@wisetack/shared-ui/components/Amplitude";
import { formatUsPhone } from "@wisetack/shared-ui/utils/format";
import { Loader } from "../components/Loader"

import { submitPin, setError } from "../store/actions/merchantActions";
import { customizeErrorMessage } from "../utils/format"
import styles from "./MerchantPinPage.module.scss";

const pageName = "Merchant Pin Page"

const stateNames = [
    "isLoading",
    "errorMessage",
    "username",
    "user",
    "users",
    "submitPinRequestId"
]

const logProps = {page: pageName}

class MerchantPinPage extends React.Component {

    state = {
        pin: "",
        errors: {},
        suggestions: {},
        username: null
    };

    constructor(props) {
        super(props);
        this.validator = new MerchantFieldValidator(pageName);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent(pageName, logProps);
        this.validator.props = logProps;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {}
        if (nextProps.username) {
            if (nextProps.username !== prevState.username) {
                newState.username = nextProps.username
            }
        } else {
            const username = localStorage.getItem("merchant:username");
            if (username) {
                if (username !== prevState.username) {
                    newState.username = username
                }
            } else {
                nextProps.history.push("/");
                return null;
            }
        }
        let errorMessage = nextProps.errorMessage
        if (errorMessage) {
            if (prevState.rawError !== errorMessage) {
                newState.rawError = errorMessage
                const pinError = customizeErrorMessage(errorMessage)
                if (pinError) {
                    newState.suggestions = {pin: pinError['pin']}
                    logAmplitudeEvent("Pin error", {
                        ...logProps,
                        pinError: pinError
                    })
                } else {
                    newState.errorMessage = errorMessage
                    logAmplitudeEvent("Error message", {
                        ...logProps,
                        errorMessage
                    })
                }
            }
        } else {
            if (prevState.errorMessage) {
                newState.errorMessage = null
            }
        }
        return newState;
    }

    componentDidUpdate(prevProps) {
        if (this.props.errorMessage) {
            if (this.props.errorMessage.includes('lockout')) {
                this.props.setError(null);
                this.props.history.push("/account_locked");
                return;
            }
        }
        if (this.props.submitPinRequestId && this.props.submitPinRequestId !== prevProps.submitPinRequestId) {
            if (this.props.user) {
                setAmplitudeUserProperty("merchantId", this.props.user.merchantId);
                logAmplitudeEvent("Pin verification succeed, one account", {
                    ...logProps,
                    userId: this.props.user.userId,
                    merchantId: this.props.user.merchantId
                })
                this.props.history.push("/transaction_menu");
                return
            }
            if (this.props.users) {
                logAmplitudeEvent("Pin verification succeed, multiple accounts", logProps);
                this.props.history.push("/accounts");
                return
            }
        }
    }

    handleOnBlur = e => { };

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    handleOnButtonClick = () => {
        logAmplitudeEvent("Pressed Didn't get a code Button", logProps)
        this.props.history.push("/login_retry");
    };

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        if (name === "pin") {
            val = val.replace(/\D/g, "");
            if (val.length > 4) {
                return;
            }
        }
        this.setState({ [name]: val });
        if (val.length === 4) {
            logAmplitudeEvent("Entered pin", this.logProps)
            this.props.submitPin(val, this.state.username, null, null, uuid.v4());
        }
    };
 

    render() {
        Log.info({ state: this.state, props: this.props }, `MerchantPinPage state/props`);

        const textButtonClasses = classNames({
            [styles.text_button]: true
        });


        
        let isEmail = this.state.username.includes('@');
        const un = isEmail ? this.state.username : formatUsPhone(this.state.username);

        return (
            <Container>
                <PageHeader progress="">
                    <div>We just {isEmail ? 'emailed' : 'texted'} you</div>
                    <div style={{ paddingLeft: "15px", textAlign: "center" }}>
                        Please enter the&nbsp;verification&nbsp;code<br/>we&nbsp;sent&nbsp;to&nbsp;<b>{un}</b>
                    </div>
                </PageHeader>
                {this.props.isLoading && <Loader />}
                <div className={styles.inputs}>
                    {!this.props.isLoading && (
                        <>
                            <FormRow>
                                <FormInput
                                    autoFocus
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    name="pin"
                                    label="Enter the 4-digit code"
                                    value={this.state.pin}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    suggestions={this.state.suggestions}
                                />
                            </FormRow>
                            <Error pageName={pageName}>{this.state.errorMessage}</Error>
                            <div className="row">
                                <div className="col">
                                    <div
                                        className={textButtonClasses}
                                        onClick={this.handleOnButtonClick}
                                    >
                                        Didn't get a code?
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Container>
        );
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { submitPin, setError }
)(MerchantPinPage);
