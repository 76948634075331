/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import {connect} from "react-redux";
import {HashRouter, Route, Switch, withRouter} from "react-router-dom";
import IdleTimer from 'react-idle-timer';

import BlankPage from "@wisetack/shared-ui/views/BlankPage";
import AccountLockedPage from "@wisetack/shared-ui/views/AccountLockedPage";
import SessionExpiredPage from "@wisetack/shared-ui/views/SessionExpiredPage";
import {initAmplitude, logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {initDatadog} from "@wisetack/shared-ui/utils/Datadog";

import LoginPage from "./views/LoginPage"
import MerchantPinPage from "./views/MerchantPinPage"
import TransactionsPage from "./views/TransactionsPage"
import TransactionDetailPage from "./views/TransactionDetailPage"
import TransactionReviewPage from "./views/TransactionReviewPage"
import AccountsPage from "./views/AccountsPage"
import NewTransactionPage from "./views/NewTransactionPage"
import NewPrequalPage from "./views/NewPrequalPage"
import PaymentCalculatorPage from "./views/PaymentCalculatorPage"
import AccountSettingsPage from "./views/AccountSettingsPage"
import FiltersPage from "./views/FiltersPage"
import HelpPage from "./views/HelpPage"
import {sessionExpired} from "./store/actions/merchantActions";
import BusinessPage from "./views/BusinessPage";
import UserPage from "./views/UserPage";
import UserManagementPage from "./views/UserManagementPage";
import BusinessEditPage from "./views/BusinessEditPage";
import UserEditPage from "./views/UserEditPage";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import UserAddPage from "./views/UserAddPage";
import UserEditSettingsPage from "./views/UserEditSettingsPage"
import LinkBankModePage from "./views/LinkBankModePage";
import LinkBankManualPage from "./views/LinkBankManualPage";
import LinkBankReviewPage from "./views/LinkBankReviewPage";
import LinkBankSuccessPage from "./views/LinkBankSuccessPage";
import PrequalsPage from "./views/PrequalsPage";
import PrequalFiltersPage from "./views/PrequalFiltersPage";
import TransactionMenuPage from "./views/TransactionMenuPage";
import PrequalSentPage from "./views/PrequalSentPage";
import PrequalDetailPage from "./views/PrequalDetailPage";
import LinkBankPlaidPage from "./views/LinkBankPlaidPage"

initAmplitude(window._wtenv_?.REACT_APP_MERCHANT_AMPLITUDE_KEY);
initDatadog('merchant');

const INACTIVITY_TIMEOUT = 1000 * 60 * 30  // 30 min

// eslint-disable-next-line no-extend-native
Number.prototype.toCommaSeparatedMoney = function(n, x) {
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

class App extends Component {

  constructor(props) {
    super(props)
    this.idleTimer = null
  }

  componentDidMount() {
    const options = {
      autoConfig: true,
      debug: false,
    };
    if (window._wtenv_?.REACT_APP_ANALYTICS_FB_KEY) {
      ReactPixel.init(window._wtenv_?.REACT_APP_ANALYTICS_FB_KEY, {}, options);
    }
    if (window._wtenv_?.REACT_APP_ANALYTICS_GOOGLE_KEY) {
      ReactGA.initialize(window._wtenv_?.REACT_APP_ANALYTICS_GOOGLE_KEY);
    }
  }

  componentDidUpdate(prevProps) {
    const { location, user} = this.props;
    if (location.pathname && location.pathname !== prevProps.location.pathname) {
      if (window._wtenv_?.REACT_APP_ANALYTICS_FB_KEY) {
        ReactPixel.fbq('trackCustom', location.pathname, {userId: !!user ? user.userId : null});
      }
      if (window._wtenv_?.REACT_APP_ANALYTICS_GOOGLE_KEY) {
        ReactGA.pageview(location.pathname);
      }
    }
  }

  handleOnIdleExpiration = () => {
    this.idleTimer.start();
    if (this.props.location.pathname.match(/session_.*_expiration/) || this.props.location.pathname === '/') {
      return;
    }
    let logProps = {}
    if (this.props.user) {
      logProps.userId = this.props.user.userId;
      logProps.merchantId = this.props.user.merchantId;
    }
    logAmplitudeEvent('Session Inactivity Expiration', logProps)
    this.props.sessionExpired();
    this.props.history.replace('/session_inactivity_expiration');
  }

  render() {
    return (
      <>
        <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={INACTIVITY_TIMEOUT}
            onIdle={this.handleOnIdleExpiration}
        />
        <HashRouter>
          <div className="App">
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/login_retry" component={LoginPage} />
              <Route exact path="/pin" component={MerchantPinPage} />
              <Route exact path="/transaction_menu" component={TransactionMenuPage} />
              <Route exact path="/prequals" component={PrequalsPage} />
              <Route exact path="/prequal" component={PrequalDetailPage} />
              <Route exact path="/transactions" component={TransactionsPage} />
              <Route exact path="/filters" component={FiltersPage} />
              <Route exact path="/prequal_filters" component={PrequalFiltersPage} />
              <Route exact path="/transaction" component={TransactionDetailPage} />
              <Route exact path="/transaction_review" component={TransactionReviewPage} />
              <Route exact path="/new_transaction" component={NewTransactionPage} />
              <Route exact path="/send_transaction" component={NewTransactionPage} />
              <Route exact path="/new_prequal" component={NewPrequalPage} />
              <Route exact path="/prequal_sent" component={PrequalSentPage} />
              <Route exact path="/accounts" component={AccountsPage} />
              <Route exact path="/account_settings" component={AccountSettingsPage} />
              <Route exact path="/account_locked" component={AccountLockedPage} />
              <Route exact path="/calculator" component={PaymentCalculatorPage} />
              <Route exact path="/help" component={HelpPage} />
              <Route path="/session_inactivity_expiration" component={SessionExpiredPage} />
              <Route exact path="/business" component={BusinessPage} />
              <Route exact path="/user" component={UserPage} />
              <Route exact path="/user_management" component={UserManagementPage} />
              <Route exact path="/business_edit" component={BusinessEditPage} />
              <Route exact path="/user_edit" component={UserEditPage} />
              <Route exact path="/user_add" component={UserAddPage} />
              <Route exact path="/user_edit_settings" component={UserEditSettingsPage} />
              <Route path="/blank" component={BlankPage} />
              <Route path="/link_bank_plaid" component={LinkBankPlaidPage} />
              <Route path="/link_bank_mode" component={LinkBankModePage} />
              <Route path="/link_bank_manual" component={LinkBankManualPage} />
              <Route path="/link_bank_review" component={LinkBankReviewPage} />
              <Route path="/bank_link_success" component={LinkBankSuccessPage} />
            </Switch>
          </div>
        </HashRouter>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.merchant.user
})

export default connect(mapStateToProps, {sessionExpired})(withRouter(App));
