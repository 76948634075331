"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useApiRequest;

var _react = _interopRequireDefault(require("react"));

var _uuid = _interopRequireDefault(require("uuid"));

var _reactRedux = require("react-redux");

var _reactRouter = require("react-router");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function useApiRequest(stateName, parentRequestId) {
  var history = (0, _reactRouter.useHistory)();
  var apiRequests = (0, _reactRedux.useSelector)(function (state) {
    return state[stateName].apiRequests;
  });
  var apiError = (0, _reactRedux.useSelector)(function (state) {
    return state[stateName].apiError;
  });

  var _React$useState = _react["default"].useState(parentRequestId || ''),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      requestId = _React$useState2[0],
      setRequestId = _React$useState2[1];

  var requestInProgress = !!requestId && !!apiRequests[requestId];

  function newRequest() {
    var requestId = _uuid["default"].v4();

    setRequestId(requestId);
    return requestId;
  }

  var requestError = formatErrorMessage(apiError, requestId);

  if (requestError && stateName === 'consumer' && (requestError.includes('Token has expired') || requestError === 'Unauthorized.')) {
    history.replace('/session_inactivity_expiration');
  }

  return [requestId, requestInProgress, requestError, newRequest];
}

var formatErrorMessage = function formatErrorMessage(apiError, requestId) {
  var errorMessage = '';

  if (apiError && apiError.requestId === requestId) {
    var error = apiError.error;

    if (error.message) {
      errorMessage = error.message;
    }

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
  }

  return errorMessage;
};